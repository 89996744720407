import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getMockTestQuestions, submitMockTest } from '../services/api';
import Timer from '../components/Timer';
import { QuestionsContainer, Title, Button, QuestionContainer, OptionLabel, ErrorMessage } from '../styles/StyledComponents';
import { MockTestContainer, FixedHeader } from '../styles/StyledComponents';
import Footer from '../components/Footer';

const MockTestPage = () => {
  const { testId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const location = useLocation();
  const testData = location.state?.testData;
  const [timeLeft, setTimeLeft] = useState(testData?.duration * 60 || 0);
  const [error, setError] = useState(null);
  const initialRender = useRef(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchAndSetup = async () => {
      try {
        const response = await getMockTestQuestions(testId);
        setQuestions(response.data);
        setError(null);
        if (!timeLeft && testData?.duration !== 0) {
          setTimeLeft(response.data.duration * 60);
        }
      } catch (error) {
        console.error('Error fetching questions:', error);
        setError('Failed to load questions. Please try again.');
      }
    };
    if (initialRender.current) {
      initialRender.current = false;
      fetchAndSetup();
    }
  }, [testId, testData, timeLeft]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!isSubmitting && Object.keys(answers).length > 0) {
        e.preventDefault();
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSubmitting, answers]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (Object.keys(answers).length > 0) {
        event.preventDefault();
        if (window.confirm('Are you sure you want to leave? Your progress will be lost.')) {
          navigate('/');
        } else {
          window.history.pushState(null, '', window.location.pathname);
        }
      }
    };
  
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', handlePopState);
  
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, answers]);

  const handleAnswerChange = (questionId, answer) => {
    setAnswers(prev => ({ ...prev, [questionId]: answer }));
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    try {
      if (questions.length === 0) {
        throw new Error('No questions available');
      }
  
      const questionsWithUserAnswers = questions.map(question => {
        if (!question.questionId) {
          throw new Error(`Invalid question data: missing questionId`);
        }
  
        return {
          ...question,
          userAnswer: answers[question.questionId]
        };
      });

      navigate(`/results/${testId}`, { 
        state: {
          marksPerQuestion: testData.marksPerQuestion,
          negativeMarksPerQuestion: testData.negativeMarksPerQuestion,
          questions: questionsWithUserAnswers
        } 
      });
    } catch (error) {
      console.error('Error processing test data:', error);
      setError('An error occurred while processing your test. Please try again.');
      setIsSubmitting(false);
    }
  };

  return (
    <MockTestContainer>
      <Helmet>
        <title>{`${testData?.title + ' Mock Test'} | MockTestPro`}</title>
        <meta name="description" content={`Take the ${testData?.title || 'mock test'} to practice and improve your skills. Test your knowledge with our comprehensive question set.`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <FixedHeader>
        <Title>{testData?.title + ' Mock Test'}</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {timeLeft > 0 && testData?.duration !== 0 && (
          <Timer initialTime={timeLeft} onTimeUp={handleSubmit} />
        )}
      </FixedHeader>
      <QuestionsContainer>
        {questions.map((question, index) => (
          <QuestionContainer key={question.questionId}>
            <h3>Question {index + 1}</h3>
            <p>{question.questionText}</p>
            {question.options.map((option, optionIndex) => (
              <OptionLabel key={optionIndex}>
                <input
                  type="radio"
                  name={`question_${question.questionId}`}
                  value={optionIndex}
                  checked={answers[question.questionId] === optionIndex}
                  onChange={() => handleAnswerChange(question.questionId, optionIndex)}
                />
                <b>{String.fromCharCode(65 + optionIndex)}</b>. {option}
              </OptionLabel>
            ))}
          </QuestionContainer>
        ))}
      </QuestionsContainer>
      <Button onClick={handleSubmit}>Submit Test</Button>
      <Footer />
    </MockTestContainer>
  );
};

export default MockTestPage;
