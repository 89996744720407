import React from 'react';
import styled from 'styled-components';

const ComingSoonWrapper = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Title = styled.h3`
  color: #4a90e2;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
`;

const ExamComingSoon = ({ examName }) => (
  <ComingSoonWrapper>
    <Title>{examName} - Coming Soon!</Title>
    <Message>
      We're working hard to bring you comprehensive practice materials for {examName}. 
      Stay tuned for an unparalleled preparation experience. Your success is our priority!
    </Message>
  </ComingSoonWrapper>
);

export default ExamComingSoon;
