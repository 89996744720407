import React from 'react';
import BaseHomePage from '../BaseHomePage';
// import { exams } from './IBPSData';

const exams = [
  { id: 1, name: "IBPS PO", description: "Probationary Officer exam for various banks" },
  { id: 2, name: "IBPS Clerk", description: "Clerk exam for various banks" },
  { id: 3, name: "IBPS SO", description: "Specialist Officer exam for various banks" },
  { id: 4, name: "IBPS RRB", description: "Regional Rural Banks exam for various posts" },
];

const seoContent = {
  title: "IBPS Exam Preparation | MockTest Pro",
  description: "Prepare for IBPS exams with MockTest Pro. Practice tests, study materials, and expert guidance for IBPS PO, Clerk, SO, and RRB exams.",
  keywords: ["IBPS", "Institute of Banking Personnel Selection", "IBPS PO", "IBPS Clerk", "exam preparation"],
  heroTitle: "Ace Your IBPS Exams with MockTest Pro",
  heroSubtitle: "Comprehensive practice tests for all IBPS exams",
  additionalContent: `
 <h2>Why Choose MockTest Pro for IBPS Exam Preparation?</h2>
<p>At MockTest Pro, we provide the most comprehensive and up-to-date questions for IBPS exams. Our platform is meticulously designed to help you excel in various IBPS exams, including PO, Clerk, SO, and RRB.</p>

<h3>Key Features:</h3>
<ul>
  <li><strong>Extensive Question Bank:</strong> Covering all IBPS exam topics to ensure thorough preparation.</li>
  <li><strong>Realistic Mock Tests:</strong> Simulate actual exam conditions to boost your confidence and readiness.</li>
  <li><strong>Detailed Performance Analytics:</strong> Track your progress with in-depth performance reports.</li>
  <li><strong>Expert-Curated Study Materials:</strong> Access study materials and tips curated by industry experts.</li>
</ul>
  `
};

const IBPSHomePage = () => {
  return (
    <BaseHomePage
      examType="IBPS"
      exams={exams}
      seoContent={seoContent}
    />
  );
};

export default IBPSHomePage;
