import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/HomePage.module.css';
import Copyright from '../components/Copyright';
import FeatureCarousel from '../components/FeatureCarousel';
import ExamComingSoon from '../components/ExamComingSoon';
import Footer from '../components/Footer';

const BaseHomePage = ({ examType, exams, features, seoContent }) => {
  const navigate = useNavigate();
  const [selectedExam, setSelectedExam] = useState(null);

  const handleStartPractice = (exam) => {
    setSelectedExam(exam);
    // navigate(`/${examType.toLowerCase()}/exam/${examId}`);
  };

  return (
    <div className={styles.homePage}>
      <Helmet>
        <title>{seoContent.title}</title>
        <meta name="description" content={seoContent.description} />
        <meta name="keywords" content={seoContent.keywords.join(', ')} />
      </Helmet>

      <main className={styles.main}>
        <div className={styles.hero}>
          <h1 className={styles.heroTitle}>{seoContent.heroTitle}</h1>
          <p className={styles.heroSubtitle}>{seoContent.heroSubtitle}</p>
        </div>
        
        <section className={styles.featureSection}>
          <FeatureCarousel features={features} />
        </section>

                {/* <section className={styles.examsSection}>
         <h2 className={styles.sectionTitle}>Available Exams</h2>
          <div className={styles.examGrid}>
             {exams.map((exam) => (
              <div key={exam.id} className={styles.examCard}>
                <h3 className={styles.examTitle}>{exam.name}</h3>
                <p className={styles.examDescription}>{exam.description}</p>
                <button className={styles.button} onClick={() => handleStartPractice(exam.id)}>Start Practice</button>
              </div>
            ))}
          </div>

        </section> */}
        <section className={styles.examsSection}>
          <h2 className={styles.sectionTitle}>Available {examType} Exams</h2>
          <div className={styles.examGrid}>
            {exams.map((exam) => (
              <div key={exam.id} className={styles.examCard}>
                <h3 className={styles.examTitle}>{exam.name}</h3>
                <p className={styles.examDescription}>{exam.description}</p>
                <button className={styles.button} onClick={() => handleStartPractice(exam)}>Start Practice</button>
              </div>
            ))}
          </div>
        </section>

        {selectedExam && (
        <section className={styles.selectedExamSection}>
          <ExamComingSoon examName={selectedExam.name} />
        </section>
      )}

        <section className={styles.additionalContentSection}>
        {/* <div className={styles.additionalContentWrapper}> */}
          <div className={styles.additionalContent} dangerouslySetInnerHTML={{ __html: seoContent.additionalContent }} />
        {/* </div> */}
      </section>

      <Footer />
      </main>
    </div>
  );
};

export default BaseHomePage;
