import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight, BookOpen, User, Award, TrendingUp, Clock, Target, Brain, Medal, Zap, Briefcase } from 'lucide-react';
import { RefreshCw, BarChart2, Smartphone, Search, Percent } from 'lucide-react';
import styles from './../styles/HomePage.module.css';

const features = [
  { icon: BookOpen, title: "Extensive Practice", description: "Access a vast library of mock exams" },
  { icon: User, title: "Personalized Learning", description: "Receive tailored study plans" },
  { icon: Award, title: "Performance Tracking", description: "Monitor progress with analytics" },
  // { icon: TrendingUp, title: "Adaptive Difficulty", description: "Questions adapt to your skill level" },
  { icon: Clock, title: "Timed Tests", description: "Simulate real exam conditions" },
  // { icon: Target, title: "Goal Setting", description: "Set and track your study goals" },
  // { icon: Brain, title: "Memory Techniques", description: "Learn effective memorization strategies" },
  { icon: Medal, title: "Achievements", description: "Earn badges for your progress" },
  { icon: Zap, title: "Quick Quizzes", description: "Test your knowledge on the go" },
  // { icon: Briefcase, title: "Career Guidance", description: "Get advice on exam-related careers" },
  { icon: Target, title: "Realistic Mock Tests", description: "Experience exam-like conditions with our carefully crafted mock tests" },
  { icon: RefreshCw, title: "Variety of Exams", description: "Access mock tests for a wide range of professional exams" },
  { icon: BarChart2, title: "Instant Results", description: "Get immediate feedback on your performance after each mock test" },
  { icon: Smartphone, title: "Cross-Platform Access", description: "Take mock tests seamlessly across desktop, tablet, and mobile devices" },
  { icon: Search, title: "Detailed Analysis", description: "Receive comprehensive breakdowns of your mock test performance" },
  { icon: Percent, title: "100% Free", description: "Access all our high-quality mock tests without any cost" },
];

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className={styles.featureCard}>
    <div className={styles.iconWrapper}>
      <Icon size={32} className={styles.icon} />
    </div>
    <h3 className={styles.featureTitle}>{title}</h3>
    <p className={styles.featureDescription}>{description}</p>
  </div>
);

const FeatureCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
  }, []);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + features.length) % features.length);
  }, []);

  useEffect(() => {
    let intervalId;
    if (!isHovered) {
      intervalId = setInterval(nextSlide, 5000);
    }
    return () => clearInterval(intervalId);
  }, [isHovered, nextSlide]);

  const visibleFeatures = [...features.slice(currentIndex), ...features.slice(0, currentIndex)].slice(0, 4);

  return (
    <div className={styles.carouselWrapper} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className={styles.carousel}>
        {visibleFeatures.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
      <button className={`${styles.carouselButton} ${styles.prevButton}`} onClick={prevSlide}>
        <ChevronLeft size={24} />
      </button>
      <button className={`${styles.carouselButton} ${styles.nextButton}`} onClick={nextSlide}>
        <ChevronRight size={24} />
      </button>
    </div>
  );
};

export default FeatureCarousel;