import React from 'react';
import BaseHomePage from '../BaseHomePage';
// import { exams } from './RRBData';

const exams = [
  { id: 1, name: "RRB NTPC", description: "Non-Technical Popular Categories exam for various posts in Indian Railways" },
  { id: 2, name: "RRB Group D", description: "Group D exam for various posts in Indian Railways" },
  { id: 3, name: "RRB JE", description: "Junior Engineer exam for technical posts in Indian Railways" },
  { id: 4, name: "RRB ALP", description: "Assistant Loco Pilot and Technician exam for technical posts in Indian Railways" },
  { id: 5, name: "RRB ASM", description: "Assistant Station Master exam for operational posts in Indian Railways" },
  { id: 6, name: "RRB Ministerial and Isolated Categories", description: "Exam for ministerial and isolated category posts in Indian Railways" },
  { id: 7, name: "RRB Health and Malaria Inspector", description: "Exam for health and malaria inspector posts in Indian Railways" },
];

const seoContent = {
  title: "RRB Exam Preparation | MockTest Pro",
  description: "Prepare for RRB exams with MockTest Pro. Practice tests, study materials, and expert guidance for RRB NTPC, Group D, JE, and more.",
  keywords: ["RRB", "Railway Recruitment Board", "RRB NTPC", "RRB Group D", "exam preparation"],
  heroTitle: "Ace Your RRB Exams with MockTest Pro",
  heroSubtitle: "Comprehensive practice tests for all RRB exams",
  additionalContent: `
 <h2>Why Choose MockTest Pro for RRB Exam Preparation?</h2>
<p>At MockTest Pro, we provide the most comprehensive and up-to-date questions for RRB exams. Our platform is meticulously designed to help you excel in various RRB exams, including NTPC, Group D, JE, and more.</p>

<h3>Key Features:</h3>
<ul>
  <li><strong>Extensive Question Bank:</strong> Covering all RRB exam topics to ensure thorough preparation.</li>
  <li><strong>Realistic Mock Tests:</strong> Simulate actual exam conditions to boost your confidence and readiness.</li>
  <li><strong>Detailed Performance Analytics:</strong> Track your progress with in-depth performance reports.</li>
  <li><strong>Expert-Curated Study Materials:</strong> Access study materials and tips curated by industry experts.</li>
</ul>
  `
};

const RRBHomePage = () => {
  return (
    <BaseHomePage
      examType="RRB"
      exams={exams}
      seoContent={seoContent}
    />
  );
};

export default RRBHomePage;
