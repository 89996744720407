import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Title, SubTitle, QuestionContainer, Button } from '../styles/StyledComponents';
import { ScoreSection, ScoreValue, ScoreLabel, ProgressBar } from '../styles/StyledComponents';
import { SuccessMessage, EncouragementMessage } from '../styles/StyledComponents';
import Footer from '../components/Footer';

const ResultsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { marksPerQuestion, negativeMarksPerQuestion, questions } = location.state;
  const totalQuestions = questions.length;
  const score = questions.reduce((acc, question) => {
    if (question.userAnswer === question.correctAnswer) {
      return acc + marksPerQuestion;
    } else if (question.userAnswer !== undefined) {
      return acc - negativeMarksPerQuestion;
    }
    return acc;
  }, 0);

  const totalMarks = totalQuestions * marksPerQuestion;
  const percentage = ((score / totalMarks) * 100).toFixed(2);

  return (
    <Container>
      <Helmet>
        <title>Test Results | MockTestPro</title>
        <meta name="description" content={`View your test results. You scored ${score}/${totalMarks} (${percentage}%). Analyze your performance and improve your skills.`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Title>Test Results</Title>
      <ScoreSection>
        <ScoreValue>{score}/{totalMarks}</ScoreValue>
        <ScoreLabel>Your Score</ScoreLabel>
        <ProgressBar percentage={percentage} />
        <ScoreLabel>{percentage}% Correct</ScoreLabel>
        {percentage >= 70 ? (
          <SuccessMessage>
            Congratulations! You've passed the test. Great job on your performance!
            Review the questions to further strengthen your knowledge and aim even higher next time.
          </SuccessMessage>
        ) : (
          <EncouragementMessage>
            Keep practicing! You're on the right track. Review the questions and try again to improve your score.
          </EncouragementMessage>
        )}
      </ScoreSection>

      <SubTitle>Review</SubTitle>

      {questions.map((question, index) => (
        <QuestionContainer key={index}>
          <h3>Question {index + 1}</h3>
          <p>{question.questionText}</p>
          {question.options.map((option, optionIndex) => (
            <p key={optionIndex} style={{
              color: optionIndex === question.correctAnswer
                ? 'green'
                : (optionIndex === question.userAnswer && optionIndex !== question.correctAnswer)
                  ? 'red'
                  : 'black'
            }}>
              {String.fromCharCode(65 + optionIndex)}. {option}
              {optionIndex === question.userAnswer && ' (Your Answer)'}
              {optionIndex === question.correctAnswer && ' (Correct Answer)'}
            </p>
          ))}
        </QuestionContainer>
      ))}

      <Link to="/">
        <Button data-secondary="true">Back to Home</Button>
      </Link>
      <Footer />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "LearningAssessment",
          "name": "Test Results",
          "educationalLevel": "Varied",
          "learningResourceType": "Quiz",
          "interactionType": "http://schema.org/ChoiceInteraction",
          "numberOfQuestions": totalQuestions,
          "typicalAgeRange": "18-",
          "audience": {
            "@type": "Audience",
            "audienceType": "Test Takers"
          }
        })}
      </script>
    </Container>
  );
};

export default ResultsPage;
