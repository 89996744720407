import React from 'react';
import BaseHomePage from '../BaseHomePage';
// import { exams } from './SSCData';

const exams = [
  { id: 1, name: "SSC CGL", description: "Combined Graduate Level exam for various government posts" },
  { id: 2, name: "SSC CHSL", description: "Combined Higher Secondary Level exam for clerks, postal assistants, and data entry operators" },
  { id: 3, name: "SSC MTS", description: "Multitasking Staff exam for non-technical staff in government departments" },
  { id: 4, name: "SSC JE", description: "Junior Engineer exam for civil, electrical, mechanical, and quantity surveying & contracts" },
  { id: 5, name: "SSC CPO", description: "Central Police Organization exam for sub-inspectors in Delhi Police, CAPFs, and ASI in CISF" },
  { id: 6, name: "SSC Stenographer", description: "Stenographer Grade 'C' & 'D' exam for stenographers in government departments" },
  { id: 7, name: "SSC GD", description: "General Duty exam for constables in CAPFs, NIA, SSF, and rifleman in Assam Rifles" },
  { id: 8, name: "SSC JHT", description: "Junior Hindi Translator exam for translators and Hindi pradhyapak" },
];

const seoContent = {
  title: "SSC Exam Preparation | MockTest Pro",
  description: "Prepare for SSC exams with MockTest Pro. Practice tests, study materials, and expert guidance for SSC CGL, CHSL, and more.",
  keywords: ["SSC", "Staff Selection Commission", "SSC CGL", "SSC CHSL", "exam preparation"],
  heroTitle: "Ace Your SSC Exams with MockTest Pro",
  heroSubtitle: "Comprehensive practice tests for all SSC exams",
  additionalContent: `
 <h2>Why Choose MockTest Pro for SSC Exam Preparation?</h2>
<p>At MockTest Pro, we provide the most comprehensive and up-to-date questions for SSC exams. Our platform is meticulously designed to help you excel in various SSC exams, including CGL, CHSL, MTS, and more.</p>

<h3>Key Features:</h3>
<ul>
  <li><strong>Extensive Question Bank:</strong> Covering all SSC exam topics to ensure thorough preparation.</li>
  <li><strong>Realistic Mock Tests:</strong> Simulate actual exam conditions to boost your confidence and readiness.</li>
  <li><strong>Detailed Performance Analytics:</strong> Track your progress with in-depth performance reports.</li>
  <li><strong>Expert-Curated Study Materials:</strong> Access study materials and tips curated by industry experts.</li>
</ul>

  `
};

const SSCHomePage = () => {
  return (
    <BaseHomePage
      examType="SSC"
      exams={exams}
      seoContent={seoContent}
    />
  );
};

export default SSCHomePage;
