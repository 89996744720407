import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Container, Title } from '../../styles/StyledComponents';
import RotatingTitle from '../../components/RotatingTitle';
import Copyright from '../../components/Copyright';
import styled from 'styled-components';
import Footer from '../../components/Footer';

const StateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const StateCard = styled.div`
  background-color: var(--card-bg-color, #ffffff);
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  padding: 25px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 480px) {
    padding: 15px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.05)
    );
    transform: rotate(45deg);
    pointer-events: none;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.2);
  }
`;

const StateFlag = styled.img`
  width: 140px;
  height: 84px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 120px;
    height: 72px;
  }

  @media (max-width: 480px) {
    width: 100px;
    height: 60px;
  }

  ${StateCard}:hover & {
    transform: scale(1.05);
  }
`;

const StateName = styled.h3`
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 10px;
  color: var(--secondary-color, #2c3e50);
`;

const TestCount = styled.p`
  color: var(--accent-color, #e74c3c);
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 15px;
`;

const StartButton = styled.button`
  display: inline-block;
  padding: 12px 30px;
  background-color: var(--primary-color, #3498db);
  color: white;
  text-decoration: none;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-weight: 500;
  letter-spacing: 0.5px;

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(52, 152, 219, 0.3);
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 500px;
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    max-width: 90%;
    font-size: 16px;
  }
  &:focus {
    outline: none;
    box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  }
`;

const states = [
  { value: 'Alabama', label: 'Alabama', Title: "Alabama Knowledge Test", examId: "ALABAMA_KNOWLEDGE_TEST", abbr: 'AL', tests: 7 },
  { value: 'Alaska', label: 'Alaska', Title: "Alaska DMV Written Test", examId: "ALASKA_DMV_WRITTEN_TEST", abbr: 'AK', tests: 12 },
  { value: 'Arizona', label: 'Arizona', Title: "Arizona Permit Test", examId: "ARIZONA_PERMIT_TEST", abbr: 'AZ', tests: 6 },
  { value: 'Arkansas', label: 'Arkansas', Title: "Arkansas Knowledge Test", examId: "ARKANSAS_KNOWLEDGE_TEST", abbr: 'AR', tests: 8 },
  { value: 'California', label: 'California', Title: "California Permit Test", examId: "CALIFORNIA_PERMIT_TEST", abbr: 'CA', tests: 4 },
  { value: 'Colorado', label: 'Colorado', Title: "Colorado Knowledge Test", examId: "COLORADO_KNOWLEDGE_TEST", abbr: 'CO', tests: 8 },
  { value: 'Connecticut', label: 'Connecticut', Title: "Connecticut DMV Written Test", examId: "CONNECTICUT_DMV_WRITTEN_TEST", abbr: 'CT', tests: 8 },
  { value: 'Delaware', label: 'Delaware', Title: "Delaware Knowledge Test", examId: "DELAWARE_KNOWLEDGE_TEST", abbr: 'DE', tests: 8 },
  { value: 'Florida', label: 'Florida', Title: "Florida Permit Test", examId: "FLORIDA_PERMIT_TEST", abbr: 'FL', tests: 4 },
  { value: 'Georgia', label: 'Georgia', Title: "Georgia Knowledge Exam", examId: "GEORGIA_KNOWLEDGE_EXAM", abbr: 'GA', tests: 5 },
  { value: 'Hawaii', label: 'Hawaii', Title: "Hawaii Permit Test", examId: "HAWAII_PERMIT_TEST", abbr: 'HI', tests: 8 },
  { value: 'Idaho', label: 'Idaho', Title: "Idaho Knowledge Test", examId: "IDAHO_KNOWLEDGE_TEST", abbr: 'ID', tests: 6 },
  { value: 'Illinois', label: 'Illinois', Title: "Illinois Written Test", examId: "ILLINOIS_WRITTEN_TEST", abbr: 'IL', tests: 6 },
  { value: 'Indiana', label: 'Indiana', Title: "Indiana Knowledge Test", examId: "INDIANA_KNOWLEDGE_TEST", abbr: 'IN', tests: 5 },
  { value: 'Iowa', label: 'Iowa', Title: "Iowa Written Knowledge Test", examId: "IOWA_WRITTEN_KNOWLEDGE_TEST", abbr: 'IA', tests: 7 },
  { value: 'Kansas', label: 'Kansas', Title: "Kansas Knowledge Test", examId: "KANSAS_KNOWLEDGE_TEST", abbr: 'KS', tests: 8 },
  { value: 'Kentucky', label: 'Kentucky', Title: "Kentucky Permit Test", examId: "KENTUCKY_PERMIT_TEST", abbr: 'KY', tests: 6 },
  { value: 'Louisiana', label: 'Louisiana', Title: "Louisiana Written Test", examId: "LOUISIANA_WRITTEN_TEST", abbr: 'LA', tests: 6 },
  { value: 'Maine', label: 'Maine', Title: "Maine Knowledge Exam", examId: "MAINE_KNOWLEDGE_EXAM", abbr: 'ME', tests: 8 },
  { value: 'Maryland', label: 'Maryland', Title: "Maryland Permit Test", examId: "MARYLAND_PERMIT_TEST", abbr: 'MD', tests: 6 },
  { value: 'Massachusetts', label: 'Massachusetts', Title: "Massachusetts Permit Test", examId: "MASSACHUSETTS_PERMIT_TEST", abbr: 'MA', tests: 5 },
  { value: 'Michigan', label: 'Michigan', Title: "Michigan Knowledge Test", examId: "MICHIGAN_KNOWLEDGE_TEST", abbr: 'MI', tests: 4 },
  { value: 'Minnesota', label: 'Minnesota', Title: "Minnesota Knowledge Test", examId: "MINNESOTA_KNOWLEDGE_TEST", abbr: 'MN', tests: 6 },
  { value: 'Mississippi', label: 'Mississippi', Title: "Mississippi Written Test", examId: "MISSISSIPPI_WRITTEN_TEST", abbr: 'MS', tests: 6 },
  { value: 'Missouri', label: 'Missouri', Title: "Missouri Written Test", examId: "MISSOURI_WRITTEN_TEST", abbr: 'MO', tests: 6 },
  { value: 'Montana', label: 'Montana', Title: "Montana Knowledge Test", examId: "MONTANA_KNOWLEDGE_TEST", abbr: 'MT', tests: 7 },
  { value: 'Nebraska', label: 'Nebraska', Title: "Nebraska Permit Test", examId: "NEBRASKA_PERMIT_TEST", abbr: 'NE', tests: 10 },
  { value: 'Nevada', label: 'Nevada', Title: "Nevada Permit Test", examId: "NEVADA_PERMIT_TEST", abbr: 'NV', tests: 5 },
  { value: 'New Hampshire', label: 'New Hampshire', Title: "New Hampshire Written Test", examId: "NEW_HAMPSHIRE_WRITTEN_TEST", abbr: 'NH', tests: 6 },
  { value: 'New Jersey', label: 'New Jersey', Title: "New Jersey Knowledge Test", examId: "NEW_JERSEY_KNOWLEDGE_TEST", abbr: 'NJ', tests: 4 },
  { value: 'New Mexico', label: 'New Mexico', Title: "New Mexico Written Test", examId: "NEW_MEXICO_WRITTEN_TEST", abbr: 'NM', tests: 8 },
  { value: 'New York', label: 'New York', Title: "New York Permit Test", examId: "NEW_YORK_PERMIT_TEST", abbr: 'NY', tests: 6 },
  { value: 'North Carolina', label: 'North Carolina', Title: "North Carolina Knowledge Test", examId: "NORTH_CAROLINA_KNOWLEDGE_TEST", abbr: 'NC', tests: 6 },
  { value: 'North Dakota', label: 'North Dakota', Title: "North Dakota Knowledge Test", examId: "NORTH_DAKOTA_KNOWLEDGE_TEST", abbr: 'ND', tests: 10 },
  { value: 'Ohio', label: 'Ohio', Title: "Ohio Knowledge Test", examId: "OHIO_KNOWLEDGE_TEST", abbr: 'OH', tests: 4 },
  { value: 'Oklahoma', label: 'Oklahoma', Title: "Oklahoma Written Test", examId: "OKLAHOMA_WRITTEN_TEST", abbr: 'OK', tests: 7 },
  { value: 'Oregon', label: 'Oregon', Title: "Oregon Knowledge Test", examId: "OREGON_KNOWLEDGE_TEST", abbr: 'OR', tests: 6 },
  { value: 'Pennsylvania', label: 'Pennsylvania', Title: "Pennsylvania Permit Test", examId: "PENNSYLVANIA_PERMIT_TEST", abbr: 'PA', tests: 6 },
  { value: 'Rhode Island', label: 'Rhode Island', Title: "Rhode Island Permit Test", examId: "RHODE_ISLAND_PERMIT_TEST", abbr: 'RI', tests: 10 },
  { value: 'South Carolina', label: 'South Carolina', Title: "South Carolina Permit Test", examId: "SOUTH_CAROLINA_PERMIT_TEST", abbr: 'SC', tests: 7 },
  { value: 'South Dakota', label: 'South Dakota', Title: "South Dakota Knowledge Test", examId: "SOUTH_DAKOTA_KNOWLEDGE_TEST", abbr: 'SD', tests: 10 },
  { value: 'Tennessee', label: 'Tennessee', Title: "Tennessee Knowledge Test", examId: "TENNESSEE_KNOWLEDGE_TEST", abbr: 'TN', tests: 6 },
  { value: 'Texas', label: 'Texas', Title: "Texas Permit Test", examId: "TEXAS_PERMIT_TEST", abbr: 'TX', tests: 5 },
  { value: 'Utah', label: 'Utah', Title: "Utah Knowledge Test", examId: "UTAH_KNOWLEDGE_TEST", abbr: 'UT', tests: 4 },
  { value: 'Vermont', label: 'Vermont', Title: "Vermont Written Test", examId: "VERMONT_WRITTEN_TEST", abbr: 'VT', tests: 12 },
  { value: 'Virginia', label: 'Virginia', Title: "Virginia Permit Test", examId: "VIRGINIA_PERMIT_TEST", abbr: 'VA', tests: 6 },
  { value: 'Washington', label: 'Washington', Title: "Washington Knowledge Test", examId: "WASHINGTON_KNOWLEDGE_TEST", abbr: 'WA', tests: 10 },
  { value: 'West Virginia', label: 'West Virginia', Title: "West Virginia Knowledge Test", examId: "WEST_VIRGINIA_KNOWLEDGE_TEST", abbr: 'WV', tests: 10 },
  { value: 'Wisconsin', label: 'Wisconsin', Title: "Wisconsin Knowledge Test", examId: "WISCONSIN_KNOWLEDGE_TEST", abbr: 'WI', tests: 5 },
  { value: 'Wyoming', label: 'Wyoming', Title: "Wyoming Written Test", examId: "WYOMING_WRITTEN_TEST", abbr: 'WY', tests: 10 }
];

// const StateCardComponent = ({ state, onSelect }) => (
//   <StateCard onClick={() => onSelect(state.abbr, `${state.examId}`)}>
//     <StateFlag
//       src={`/images/state-flags/${state.abbr.toLowerCase()}.png`}
//       alt={`${state.Title}`}
//       loading="lazy"
//     />
//     <StateName>{state.label}</StateName>
//     <TestCount>{state.tests} Test{state.tests > 1 ? 's' : ''} Available</TestCount>
//     <StartButton>Start Practice</StartButton>
//   </StateCard>
// );

const StateCardComponent = ({ state, onSelect }) => (
  <StateCard onClick={() => onSelect(state.abbr, `${state.examId}`)}>
    <StateFlag
      src={`https://flagcdn.com/w160/us-${state.abbr.toLowerCase()}.png`}
      width="160"
      alt={`${state.label} flag`}
    />
    <StateName>{state.label}</StateName>
    <TestCount>{state.tests} Test{state.tests > 1 ? 's' : ''} Available</TestCount>
    <StartButton>Start Practice</StartButton>
  </StateCard>
);

const DmvHomePage = () => {
  const [filteredStates, setFilteredStates] = useState(states);
  const navigate = useNavigate();

  const handleSelectState = (state, examId) => {
    navigate(`/exam/${examId}`);
  };

  const filterStates = (query) => {
    const filtered = states.filter(state =>
      state.label.toLowerCase().includes(query.toLowerCase()) ||
      state.abbr.toLowerCase().includes(query.toLowerCase()) || 
      state.Title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredStates(filtered);
  };

  return (
    <div style={{
      backgroundImage: `url(/images/bg-image.jpg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      minHeight: '100vh',
      padding: '20px'
    }}>
      <Container>
        <Helmet>
          <title>DMV Mock Tests & Practice Exams | Driver's License, Permit Test</title>
          <meta name="description" content="Ace your DMV test with our state-specific mock tests and practice exams. Prepare for your driver's license, permit test, written test, knowledge test or road signs exam with state-specific practice exams. Free, up-to-date, and comprehensive coverage for all 50 states. Start practicing now!" />
          <meta name="keywords" content="DMV, DMV test, DMV practice test, driver's license exam, driver's license practice test, practice test, written test, knowledge test, permit test practice, driving test questions, learner's permit mock test, DMV sample questions, road signs practice test, driver knowledge test, DMV written test practice, free DMV practice test, practice test, driving test, state-specific, license exam, free DMV practice test, state-specific DMV tests, learner's permit mock test, road signs test" />
          <link rel="canonical" href="https://dmv.mocktestpro.com" />
        </Helmet>

        <RotatingTitle />

        <main>
          <SearchContainer>
            <SearchInput
              id="search-input"
              type="text"
              placeholder="Search for your state..."
              onChange={(e) => filterStates(e.target.value)}
            />
          </SearchContainer>
          <StateGrid>
            {filteredStates.map((state) => (
              <StateCardComponent key={state.abbr} state={state} onSelect={handleSelectState} />
            ))}
          </StateGrid>
        </main>
<Footer />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "DMV Practice Tests for All 50 States",
            "description": "Free, comprehensive DMV practice tests for all 50 US states. Prepare for your driver's license exam with our up-to-date question banks.",
            "url": "https://dmv.mocktestpro.com",
            "provider": {
              "@type": "Organization",
              "name": "MockTestPro",
              "url": "https://dmv.mocktestpro.com"
            },
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "audience": {
              "@type": "Audience",
              "audienceType": "Driver's License Applicants"
            },
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://dmv.mocktestpro.com/exam/{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          })}
        </script>
      </Container>
    </div>
  );
};

export default DmvHomePage;