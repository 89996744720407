import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getExam, getMockTests } from '../services/api';
import { Container, Title, SubTitle, List, ListItem, Button, Card, Loader, TestContainer } from '../styles/StyledComponents';
import { FaBook, FaClock, FaClipboardCheck, FaClipboardList } from 'react-icons/fa';
import Footer from '../components/Footer';

const ExamPage = () => {
  const { examId } = useParams();
  const [exam, setExam] = useState(null);
  const [mockTests, setMockTests] = useState([]);
  const [loading, setLoading] = useState(true);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      fetchExamAndMockTests();
    }
  }, [examId]);

  const fetchExamAndMockTests = async () => {
    try {
      setLoading(true);
      const examResponse = await getExam(examId);
      setExam(examResponse.data);
      const mockTestsResponse = await getMockTests(examResponse.data.examId);
      setMockTests(mockTestsResponse.data);
    } catch (error) {
      console.error('Error fetching exam and mock tests:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader></Loader>;
  if (!exam) return <Container>Exam not found</Container>;

  return (
    <Container>
      <Helmet>
        <title>{`${exam.title} | MockTestPro`}</title>
        <meta name="description" content={`Prepare for your ${exam.title} with our comprehensive mock tests. Practice now and boost your confidence!`} />
        <meta name="keywords" content={`${exam.title}, mock test, exam preparation, practice test`} />
      </Helmet>

      <Title>{exam.title}</Title>
      <Card>
        <p><FaBook aria-hidden="true" /> <span>{exam.description}</span></p>
        {exam.duration > 0 && (
          <p>
            <FaClock aria-hidden="true" />
            <span>Duration: {exam.duration} minutes</span>
          </p>
        )}

        <p><FaClipboardCheck aria-hidden="true" /> <span>Total Questions: {exam.totalQuestions}</span></p>
      </Card>
      <SubTitle>
        <FaClipboardList aria-hidden="true" style={{ marginRight: '0.5em' }} /> Practice with Mock Tests
      </SubTitle>
      <p style={{ marginLeft: '1.5em', color: '#555' }}>Choose from {mockTests.length} available tests to challenge your knowledge and prepare for the exam!</p>
      <List>
        {mockTests.map(test => (
          <ListItem key={test.testId}>
            <Link to={`/mocktest/${test.testId}`} state={{ testData: test }} style={{ textDecoration: 'none' }}>
              <TestContainer>
                <div className="test-title">{test.title || `Mock Test ${test.testId}`}</div>
                <div className="test-info">
                  Questions: {test.totalQuestions} |
                  Marks per Question: {test.marksPerQuestion} |
                  {test.negativeMarksPerQuestion !== 0 && `Negative Marks per Question: ${test.negativeMarksPerQuestion} |`}
                  Total Marks: {test.totalMarks}
                  {test.duration !== 0 && ` | Duration: ${test.duration} minutes`}
                </div>
              </TestContainer>
            </Link>
          </ListItem>
        ))}
      </List>
      <Link to="/">
        <Button data-secondary="true">Back to Home</Button>
      </Link>
      <Footer />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Website",
          "name": exam.title,
          "description": exam.description,
          "provider": {
            "@type": "Organization",
            "name": "MockTestPro",
            "sameAs": "https://mocktestpro.com"
          },
          "hasCourseInstance": mockTests.map(test => ({
            "@type": "CourseInstance",
            "name": test.title || `Mock Test ${test.testId}`,
            "courseMode": "online"
          }))
        })}
      </script>
    </Container>
  );
};

export default ExamPage;
